export default [
  {
    name: "nannybasicinfo",
    label: "Basic Information",
    questions: [
      {
        name: "firstName",
        label: "First Name",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "middleName",
        label: "Middle Name",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "lastName",
        label: "Last Name",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "nickName",
        label: "List All Nicknames",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "legalNames",
        label: "List any and all legal names used in the past",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "dob",
        label: "Date of Birth",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "mm/dd/yyyy",
      },
      {
        name: "age",
        label: "age",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "currentAddress",
        label: "Current / Temporary Address",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "city",
        label: "City",
        type: "input",
        required: true,
      },
      {
        name: "state",
        label: "State",
        type: "state",
        required: true,
      },
      {
        name: "zip",
        label: "Zip Code",
        type: "input",
        required: true,
      },
      {
        name: "permanentAddress",
        label: "Permanent Address",
        type: "input",
        subType: "text",
        required: false,
        placeholder: "Aka a place that is always home, or a parents house",
      },
      {
        name: "city2",
        label: "City",
        type: "input",
        required: true,
      },
      {
        name: "state2",
        label: "State",
        type: "state",
        required: true,
      },
      {
        name: "zip2",
        label: "Zip Code",
        type: "input",
        required: true,
      },
      {
        name: "phonenumber1",
        label: "Cell Phone #",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "What is the best phone number for us to contact you at?",
      },
      {
        name: "email1",
        label: "E-mail",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "What is the best email for us to contact you at?",
      },
      {
        name: "emergencycontact",
        label: "Emergency Contact",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "Full Name, Phone #, Relation",
      },
      {
        name: "allergies1",
        label: "List all allergies:",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "Dogs? Cats? Pollen? Dust? Medications? Etc...",
      },
      {
        name: "currentsitch",
        label: "What is your current living situation?",
        type: "textArea",
        required: true,
        placeholder:
          "Due to Covid-19 we are asking for more information on who you live with",
        rows: 10, // The number of rows for the text area
      },
    ],
  },
  {
    name: "Background & Legal",
    label: "Background & Legal",
    questions: [
      {
        name: "legalwork",
        label: "Are you legally permitted to work in the United States?",
        type: "radio",
        options: ["yes", "no", "unsure"],
        checked: "yes",
      },
      {
        name: "background1",
        label: "Will your background check come back clear?",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "If not, please explain...",
      },
      {
        name: "driverslicense",
        label: "Do you have a valid Drivers License?",
        type: "radio",
        options: ["yes", "no"],
        checked: "yes",
      },
      {
        name: "autoinsurance",
        label: "Do you have proof of Auto Insurance?",
        type: "radio",
        options: ["yes", "no"],
        checked: "yes",
      },
      {
        name: "car1",
        label:
          "What is the make / model / year of your vehicle you plan to drive to your Nanny job?",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "drivingrecord",
        label: "Will your driving record come back clear?",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "If not, please explain...",
      },
    ],
  },
  {
    name: "Experience",
    label: "Experience",
    questions: [
      {
        name: "experience1",
        label: "How many years of experience do you have as a Nanny?",
        type: "multiselect",
        options: [
          "0-12 Months",
          "13-24 Months",
          "25-36 Months",
          "3-6 years",
          "6-10 years",
          "10+ years",
        ],
      },
      {
        name: "experience2",
        label: "What other child related experience do you have?",
        type: "multiselect",
        options: [
          "babysitter",
          "daycare worker",
          "in-home daycare",
          "I am a parent",
          "teacher",
          "camp counselor",
          "related college major",
          "coach",
          "younger siblings",
        ],
      },
      {
        name: "teacher",
        label: "If you are qualified to be a teacher, are you interested in leading a Learning Pod during Covid-19",
        type: "radio",
        options: ["yes", "no"],
        checked: "",
      },
      {
        name: "experience3",
        label: "Please list each family you have been a Nanny for",
        type: "textArea",
        required: true,
        placeholder:
          "Include family name, ages of kids, employment dates, and a phone # for this family",
        rows: 10, // The number of rows for the text area
      },
      {
        name: "experience4",
        label:
          "Which family provided your best experience as a nanny, and why?",
        type: "textArea",
        required: true,
        placeholder: "",
        rows: 10, // The number of rows for the text area
      },
      {
        name: "experience5",
        label:
          "Which, if any, proved to be less positive experiences for you as a nanny, and why? ",
        type: "textArea",
        required: true,
        placeholder: "",
        rows: 10, // The number of rows for the text area
      },
      {
        name: "experience6",
        label: "Why do you feel you would be a fantastic Nanny?",
        type: "textArea",
        required: true,
        placeholder: "",
        rows: 10, // The number of rows for the text area
      },
      {
        name: "experience7",
        label: "Why are you seeking the assistance of our Agency?",
        type: "textArea",
        required: true,
        placeholder: "",
        rows: 10, // The number of rows for the text area
      },
      {
        name: "experience8",
        label: "Have you been legally employed as a Nanny in the past?",
        type: "textArea",
        required: true,
        placeholder:
          "This means you received a W-2 or 1099, paid taxes, and that your nanny family paid a portion of your taxes and other benefits",
        rows: 10, // The number of rows for the text area
      },
      {
        name: "experience9",
        label: "Use this space below to pitch yourself to our service",
        type: "textArea",
        required: true,
        placeholder:
          "Why do you feel you are an amazing nanny? Do you have any additional skills or experience that will be beneficial to your future Nanny Family? Is there anything we should know about your childcare philosophy or other views?",
        rows: 10, // The number of rows for the text area
      },
    ],
  },
  {
    name: "Job Preferences",
    label: "Job Preferences",
    questions: [
      {
        name: "idealjob1:",
        label: 'Please describe your "Unicorn Family"',
        type: "input",
        subType: "text",
        required: true,
        placeholder:
          "This is a description of what your absolute perfect (even impossible to find) employer would be like, number of children, ages of children, compensation, appreciation, vacation...etc.",
      },
      {
        name: "idealjob2",
        label: "How many children would you prefer to nanny?",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "",
      },
      {
        name: "idealjob3",
        label: "Where is your ideal job located?",
        type: "input",
        subType: "text",
        required: true,
        placeholder: "i.e. town, number of miles from home...etc",
      },
      {
        name: "liveIn",
        label: "Are you willing to consider being Live-In Nanny?",
        type: "radio",
        options: ["yes", "no"],
        checked: "",
      },
      {
        name: "idealjob4",
        label: "Are there any deal breakers?",
        type: "input",
        subType: "text",
        required: true,
        placeholder:
          "What characteristics or behavior from your Nanny Family are unacceptable to you?",
      },
    ],
  },
  {
    name: "AVAILABILITY",
    label: "Current Availability",
    questions: [
      {
        name: "currentavailability",
        label: "Are you currently employed as a Nanny, or otherwise?",
        type: "textArea",
        required: true,
        placeholder: "please explain",
        rows: 5, // The number of rows for the text area
      },
      {
        name: "currentavailability2",
        label:
          "If you are currently employed, how much notice do you need to give?",
        type: "textArea",
        required: true,
        placeholder: "",
        rows: 3, // The number of rows for the text area
      },
      {
        name: "currentavailability3",
        label: "Please explain a few options for start dates",
        type: "textArea",
        required: true,
        placeholder: "",
        rows: 3, // The number of rows for the text area
      },
      {
        name: "currentavailability4",
        label: "What hours/days/weeks are you available to Nanny?",
        type: "textArea",
        required: true,
        placeholder: "include as much detail as possible here",
        rows: 5, // The number of rows for the text area
      },
      {
        name: "term",
        label:
          "What TERM of employment are you looking for? Select all that apply",
        type: "multiselect",
        options: [
          "PERMANENT & Full Time (>12 Weeks, 30+ hours/week)",
          "PERMANENT & Part Time (>12 Weeks, 12-30 hours/week)",
          "TEMPORARY & Full Time (2-12 Weeks, 30+ hours/week)",
          "TEMPORARY & Part Time (2-12 Weeks, 12-30 hours/week)",
        ],
      },
    ],
  },
  {
    name: "nextsteps",
    label: "Photo, Confirmation, and Submit Application",
    questions: [
      {
        name: "agreements",
        label: "Please select / agree to the following",
        type: "multiselect",
        options: [
          "I understand that I am seeking legal Employment as a Nanny and will pay taxes on my earnings",
          "I will make myself available for an initial interview with My Boulder Nanny",
          "I understand that I will need to interview with potential families",
          "My Boulder Nanny will assist me in signing a contract with a Family if they offer me a job and I accept",
          "I will do my best to uphold any and all comitments agreed to :)",
        ],
      },
    ],
  },
];
