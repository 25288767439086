import React from "react";
import queryString from 'query-string';
import ApplicationForm from "@components/form/applicationForm";
import nannyFormQuestions from "@data/questions/nannyFormQuestions";
import Layout from "@components/layout/layout";
import "@styles/styles.scss";

export default (props) => {
  const formQuestions = nannyFormQuestions;

  const qs = queryString.parse(props.location.search);
  let familyID;
  if (typeof qs.familyID !== 'undefined') {
    try {
      familyID = JSON.parse(qs.familyID);
    } catch (error) {
      
    }
  }

  const formOpts = {
    userPhotoLabel: "Please provide a current photo of yourself.",
    familyID
  };

  const formTitle = familyID ? `Nanny Application Form (Family #${familyID})` : 'Nanny Application Form'

  return (
    <Layout pageTitle="Apply">
      <div style={{ height: "10vh" }}></div>
      <div className="container">
        <ApplicationForm
          formQuestions={formQuestions}
          formTitle={formTitle}
          formType={"Nanny"}
          formOpts={formOpts}
        />
      </div>
    </Layout>
  );
};
